import { Table, Tag, Button } from "antd";
import config from "@config";
import * as Icons from "@ant-design/icons";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      scroll={{ x: "max-content" }}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          align: "left",
          width: 300,
          render: (data, all) => (
            <div>
              <span className="block text-sm font-medium mb-1">{data}</span>
              {all.shl_compatible && <Tag>SHL</Tag>}
            </div>
          ),
        },
        {
          title: "Product",
          dataIndex: "product",
          key: "product",
          align: "center",
          width: 140,
          render: (_, all) =>
            all?.product && (
              <a
                href={`/products/edit/${all.product.id}`}
                className="block text-sm"
              >
                {all.product.name}
              </a>
            ),
        },
        {
          title: "Partner",
          dataIndex: "partner",
          key: "partner",
          align: "center",
          width: 140,
          render: (data) =>
            data && <span className="m-0 text-sm">{data.name}</span>,
        },
        {
          title: "Active",
          dataIndex: "active",
          key: "active",
          align: "center",
          width: 140,
          render: (data) => (data ? "Yes" : "No"),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Button href={`/tests/edit/${all.id}`}>View</Button>
          ),
        },
      ]}
    />
  );
}
