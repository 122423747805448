import { Button, Tag } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import moment from "moment";

export default function PaymentDetails({ order }) {
  return [
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">ID</span>
      <span className="text-sm font-semibold text-black">
        {order?.payment?.id}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">
        Stripe Payment ID
      </span>
      <span className="text-sm font-semibold text-black">
        {order?.payment?.stripe_payment_id || "No ID"}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Receipt</span>
      <span className="text-sm font-semibold text-black">
        {order?.payment?.stripe_payment_receipt ? (
          <a href={order?.payment?.stripe_payment_receipt} target="_blank">
            <Button
              type="link"
              className="m-0 p-0 h-auto"
              icon={<LinkOutlined />}
            >
              View Receipt
            </Button>
          </a>
        ) : (
          "No Receipt"
        )}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Amount</span>
      <span className="text-sm font-semibold text-black">
        £{(order?.payment?.amount_paid / 100).toFixed(2)}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Payment Status</span>
      <span className="text-sm font-semibold text-black">
        {order?.payment?.status === "awaiting_payment" && (
          <Tag className="m-0" color="orange">
            Awaiting Payment
          </Tag>
        )}
        {order?.payment?.status === "cancelled" && (
          <Tag className="m-0" color="red">
            Cancelled
          </Tag>
        )}
        {order?.payment?.status === "paid" && (
          <Tag className="m-0" color="green">
            Paid
          </Tag>
        )}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">
        Last Transaction
      </span>
      <span className="text-sm font-semibold text-black">
        {moment(order?.payment?.updated_at).format("HH:mm — DD MMM YYYY")}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Lookup</span>
      <span className="text-sm font-semibold text-black">
        <a href={`/payments/edit/${order?.payment?.id}`} target="_blank">
          <Button
            type="link"
            className="m-0 p-0 h-auto"
            icon={<LinkOutlined />}
          >
            View Payment
          </Button>
        </a>
      </span>
    </div>,
  ];
}
