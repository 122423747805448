import { Divider, Form, Input, Rate, DatePicker, Button, message } from "antd";
import * as API from "@api/reviews";
import UserSearch from "./options/users";
import CompanySearch from "./options/companies";
import OrderSearch from "./options/orders";
import { useState } from "react";
import moment from "moment";

export default function NewReview() {
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async (values) => {
    values.published_at = values?.published_at
      ? values.published_at.format()
      : moment()
          .subtract(Math.floor(Math.random() * 300), "days")
          .format();

    setLoading(true);
    const _res = await API.create(values);
    setLoading(false);

    if (!_res || _res?.error) {
      return message.error(
        "Unable to create review, please try again later",
        3
      );
    }

    return message.success("Review published!", 1, () =>
      window.location.reload()
    );
  };

  return (
    <Form
      layout="vertical"
      scrollToFirstError
      onFinish={onFormSubmit}
      onErrorCapture={console.log}
      onFinishFailed={console.log}
    >
      <Form.Item
        tooltip="Display name for your review."
        label="Display name"
        name="display_name"
      >
        <Input placeholder="E.g. Andrew T." />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Please select a rating" }]}
        label="Rate"
        name="score"
      >
        <Rate />
      </Form.Item>
      <Form.Item label="Review Title" name="title">
        <Input placeholder="E.g. Fantastic product!" />
      </Form.Item>
      <Form.Item label="Review Text" name="content">
        <Input.TextArea rows={4} placeholder="Enter full review text" />
      </Form.Item>
      <Divider />
      <CompanySearch />
      <UserSearch />
      <OrderSearch />
      <Divider />
      <Form.Item label="Publish Date" name="published_at">
        <DatePicker format={"DD MMM YYYY"} className="w-full" allowClear />
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button
          loading={loading}
          size="large"
          type="primary"
          block
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
