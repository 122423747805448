import adminroutes from "./admin";
import { Navigate } from "react-router-dom";
import { Loading } from "@components";
import { useRoutes } from "react-router-dom";
import { useAuth } from "@contexts";

export default function RoutesHandler(props) {
  const routes = useRoutes(adminroutes(props));
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  if (!currentUser || currentUser.role !== "admin") {
    return <Navigate to="/signin" />;
  }

  return routes;
}
