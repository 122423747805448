import { Button, Divider, message, Popconfirm } from "antd";
import * as API from "@api";
import * as Icons from "@ant-design/icons";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Fade from "react-reveal/Fade";
import Details from "./details";
import Orders from "./orders";
import Password from "./password";

export default function UserForm({ user }) {
  return (
    <Fade duration={600} distance="5px" bottom>
      <div className="card h-full">
        <div className="card-content">
          <span className="block w-full mx-auto text-center text-xl font-bold">
            Edit user details.
          </span>
          <p className="block w-full max-w-md text-center mx-auto text-base my-2">
            View and edit user details.
          </p>

          <div className="grid grid-cols-12 gap-x-4 gap-y-2 my-10">
            <Details user={user} />
            <div className="col-span-12">
              <Divider>Password</Divider>
            </div>
            <Password user={user} />
            <div className="col-span-12">
              <Divider>Orders</Divider>
            </div>
            <Orders user={user} />
          </div>
        </div>
      </div>
    </Fade>
  );
}
