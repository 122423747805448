import Fade from "react-reveal/Fade";
import { Button, Form, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";

export default function Sidebar({ loading, user }) {
  return (
    <Fade delay={200} duration={600} distance="5px" bottom>
      <div className="card">
        <div className="card-content">
          <h2 className="text-lg mb-5">{user?.display_name}</h2>
          <Form.Item name="blocked" label="Change status">
            <Select>
              <Select.Option value={false}>Active</Select.Option>
              <Select.Option value={true}>Blocked</Select.Option>
            </Select>
          </Form.Item>
          <Button
            loading={loading}
            type="primary"
            className="mt-5"
            icon={<SaveOutlined />}
            htmlType="submit"
          >
            Save User
          </Button>
        </div>
      </div>
    </Fade>
  );
}
