import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, message } from "antd";
import { Layouts } from "@components";
import * as Icons from "@ant-design/icons";
import * as API from "@api";
import SearchFilter from "./filters/search";
import Table from "./table";
import Fade from "react-reveal/Fade";

export default function Companie() {
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    getTableData();
  }, [search]);

  async function getTableData(params = pagination) {
    setTableLoading(true);
    const _res = await API.Companies.getAll({
      s: search,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setTableLoading(false);
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  return (
    <Layouts.AdminLayout
      breadcrumbs={[{ title: "Companies", url: "/companies" }]}
      title="Companies"
      extra={
        <Link to="/companies/new">
          <Button
            type="primary"
            href="/companies/new"
            icon={<Icons.PlusOutlined />}
          >
            Add Company
          </Button>
        </Link>
      }
    >
      <div className="dashboard-page">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <Fade delay={200} duration={600} distance="5px" bottom>
              <div className="card">
                <div className="card-content">
                  <div className="grid grid-cols-12 gap-4 mb-10">
                    <div className="col-span-12">
                      <SearchFilter onChange={onSearch} />
                    </div>
                  </div>

                  <Table
                    onChange={getTableData}
                    pagination={pagination}
                    dataSource={tableData}
                    loading={tableLoading}
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layouts.AdminLayout>
  );
}
