import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/products`;

export const getAll = (params) =>
  axios
    .get(`${API}/`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const getOne = ({ id }) =>
  axios
    .get(`${API}/id/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
