import Fade from "react-reveal/Fade";
import { Button } from "antd";
import * as Icons from "@ant-design/icons";
import classNames from "classnames";

export default function Types({ test, setTest, setStage, visible = false }) {
  return (
    <Fade spy={visible} duration={600} distance="5px" bottom>
      <div hidden={!visible} className="card h-full">
        <div className="card-content">
          <span className="block w-full mx-auto text-center text-xl font-bold">
            Select test type.
          </span>
          <p className="block w-full max-w-md text-center mx-auto text-base my-2">
            Please select test type to continue to the next step.
          </p>

          <div className="grid grid-cols-2 gap-6 w-full max-w-md mx-auto my-10">
            <div className="col-span-1">
              <div
                className={classNames("type-selector", {
                  selected: !test?.personality,
                })}
                onClick={() => {
                  setTest({ ...test, personality: false });
                }}
              >
                <Icons.BarChartOutlined />
                <span className="block text-sm mt-3">Reasoning Test</span>
                <Icons.CheckCircleFilled
                  hidden={test?.personality}
                  className="check-icon"
                  style={{ fontSize: 20 }}
                />
              </div>
            </div>
            <div className="col-span-1">
              <div
                className={classNames("type-selector", {
                  selected: test?.personality,
                })}
                onClick={() => {
                  setTest({ ...test, personality: true });
                }}
              >
                <Icons.RadarChartOutlined />
                <span className="block text-sm mt-3">Personality Test</span>
                <Icons.CheckCircleFilled
                  hidden={!test?.personality}
                  className="check-icon"
                  style={{ fontSize: 20 }}
                />
              </div>
            </div>
          </div>
          <Button
            onClick={() => setStage(1)}
            className="block mx-auto mt-10"
            type="primary"
          >
            Next <Icons.ArrowRightOutlined />
          </Button>
        </div>
      </div>
    </Fade>
  );
}
