import { Input } from "antd";

export default function QuestionContent({ question, onChange }) {
  return (
    <div className="col-span-12">
      <span className="block font-medium text-xs text-slate-600 mb-1">
        Answer Explanation
      </span>
      <Input.TextArea
        onChange={(e) =>
          onChange({
            key: ["explanation"],
            value: e.target.value,
          })
        }
        value={question.explanation}
        rows={6}
        placeholder="Enter answer explanation"
      />
    </div>
  );
}
