import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Pages from "@pages";
import { Loading } from "@components";
import { useAuth } from "@contexts";

import { RoutesHandler } from "@components";

export default function App(params) {
  const { loading } = useAuth();

  return (
    <Routes>
      <Route path="/signin" element={<Pages.Signin />} />
      <Route path="/logout" element={<Pages.Logout />} />
      <Route path="*" element={loading ? <Loading /> : <RoutesHandler />} />
    </Routes>
  );
}
