import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Editor({ description, setDescription }) {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={description}
      onChange={(_, editor) => {
        setDescription(editor.getData());
      }}
    />
  );
}
