import { Fragment } from "react";
import { Upload, Button, message, Input, Image, Form } from "antd";
import classnames from "classnames";
import config from "@config";
import * as API from "@api";
import * as Icons from "@ant-design/icons";

export default function LogoUploader({ logo, setLogo }) {
  return (
    <div className="col-span-12">
      {logo && (
        <Fragment>
          <Button
            className="mb-2 mx-auto block"
            onClick={() => setLogo(null)}
            danger
            type="primary"
            size="small"
            icon={<Icons.DeleteOutlined />}
          >
            Delete
          </Button>
          <div className="block w-full text-center">
            <Image
              className="w-full max-h-11 object-contain object-center mx-auto"
              src={`${config.static}${logo}`}
            />
          </div>
        </Fragment>
      )}
      <Upload.Dragger
        className={classnames({ hidden: logo })}
        multiple={false}
        showUploadList={false}
        name="img"
        accept="image/*"
        customRequest={async ({ onSuccess, onError, file }) => {
          try {
            const _res = await API.Static.uploadImage(file);

            if (!_res?.path) {
              throw new Error();
            }

            setLogo(_res.path);
            onSuccess();
          } catch (error) {
            onError();
            return message.error("Error uploading image", 2);
          }
        }}
      >
        <Icons.InboxOutlined
          style={{ fontSize: 33 }}
          className="text-neutral-700"
        />
        <span className="mt-1 block w-full text-center font-medium text-sm">
          Upload Image
        </span>
      </Upload.Dragger>
    </div>
  );
}
