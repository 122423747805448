import { Layouts, Loading } from "@components";
import * as API from "@api";
import { useEffect } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

export default function NewTest() {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const _res = await API.Tests.create();
      if (!_res || _res.error) {
        return message.error("Error creating test, please try again later.", 2);
      }

      message.success("Test created successfully.", 2);
      navigate("/tests/edit/" + _res.data.id, { replace: true });
    })();
  }, []);

  return (
    <Layouts.AdminLayout>
      <div className="flex flex-col items-center justify-center pt-10 pb-10">
        <Loading compact />
      </div>
    </Layouts.AdminLayout>
  );
}
