import { List, message, Button, Divider, Badge, Tooltip } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import * as API from "@api";

export default function Reports() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const _res = await API.Errors.getAll({ limit: 5 });
      setLoading(false);

      if (_res.error || !_res) {
        return message.error("Error loading data", 2);
      }
      setData(_res?.data?.rows);
    })();
  }, []);

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return {
          color: "error",
          text: "New",
        };
      case 2:
        return {
          color: "warning",
          text: "Unresolved",
        };
      case 3:
        return {
          color: "success",
          text: "Resolved",
        };
      default:
        return {
          color: "default",
          text: "Unknown",
        };
    }
  };

  return (
    <div className="card">
      <div className="card-content">
        <h3 className="mt-0 mb-3 text-xl">Error Reports</h3>
        <List
          loading={loading}
          dataSource={data}
          renderItem={(report) => (
            <List.Item>
              <div className="pr-10">
                <span className="text-base font-medium flex items-center">
                  {report.test.name}
                  <Tooltip title={getStatus(report.status).text}>
                    <Badge
                      className="ml-2"
                      size="small"
                      status={getStatus(report.status).color}
                    />
                  </Tooltip>
                </span>
                <span className="block text-sm text-slate-500 mb-2">
                  {report.error_type}
                  <Divider type="vertical" />
                  Question {report.question_id}
                </span>
              </div>
              <Button className="my-3" href={`/errors/edit/${report.id}`}>
                View
              </Button>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
