import { List, message, Button, Divider, Badge, Tooltip } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import * as API from "@api";

export default function Orders() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const _res = await API.Orders.getAll({ limit: 5 });
      setLoading(false);

      if (_res.error || !_res) {
        return message.error("Error loading data", 2);
      }
      setData(_res?.data?.rows);
    })();
  }, []);

  return (
    <div className="card">
      <div className="card-content">
        <h3 className="mt-0 mb-3 text-xl">Recent Orders</h3>
        <List
          loading={loading}
          dataSource={data}
          renderItem={(order) => (
            <List.Item>
              <div className="pr-10">
                <span className="text-base font-medium flex items-center">
                  {order.description}{" "}
                  {
                    <Tooltip
                      title={`Payment status: ${order?.payment?.status}`}
                    >
                      <Badge
                        size="small"
                        className="ml-2"
                        status={
                          order?.payment?.status === "paid"
                            ? "success"
                            : "warning"
                        }
                      />
                    </Tooltip>
                  }
                </span>
                <span className="block text-sm text-slate-500 my-1">
                  {order?.payment &&
                    `£${(order.payment.amount_paid / 10).toFixed(2)}`}
                  <Divider type="vertical" />
                  <a href={`/users/edit/${order?.user?.id}`}>
                    {order?.user?.display_name}
                  </a>
                  <Divider type="vertical" />
                  {moment(order?.created_at).format("DD MMM YYYY / HH:mm")}
                </span>
              </div>
              <Button className="my-3" href={`/orders/edit/${order.id}`}>
                View
              </Button>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
