import { Modal, Input, Form, Select, Alert, Radio, Divider } from "antd";
import { Loading } from "@components";
import { useEffect, useState } from "react";
import ContentUploader from "./form/content";
import ProblemForm from "./form/problem";
import ExplanationForm from "./form/explanation";
import Answers from "./form/answers/list";
import * as R from "ramda";

export default function QuestionModal({
  question = null,
  test,
  onClose,
  onSave,
}) {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(question);
  }, [question]);

  const onInputChange = ({ key, value }, next) => {
    const q = R.assocPath(key, value, data);
    setData(q, next);
  };

  return (
    <Modal
      destroyOnClose
      visible={!!data}
      title="Edit Question"
      maskClosable={false}
      onCancel={onClose}
      okText="Save Changes"
      onOk={() => onSave(data)}
      width={890}
      centered
    >
      {!data ? (
        <Loading compact />
      ) : (
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-6">
            <div className="w-full grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-6">
                <span className="block font-medium text-xs text-slate-600 mb-1">
                  ID
                </span>
                <Input disabled value={data?.id} />
              </div>
              <div className="col-span-12 md:col-span-6">
                <span className="block font-medium text-xs text-slate-600 mb-1">
                  Answer Format
                </span>
                <Select
                  value={data?.type}
                  onChange={(value) => onInputChange({ key: ["type"], value })}
                  className="w-full"
                  placeholder="Choose type"
                >
                  <Select.Option value={1}>Radio text</Select.Option>
                  <Select.Option value={3}>Radio image</Select.Option>
                  <Select.Option value={2}>Multiple-choice text</Select.Option>
                  <Select.Option value={4}>Best / worst response</Select.Option>
                  <Select.Option value={5}>
                    Least-to-most eff. response
                  </Select.Option>
                </Select>
              </div>
              <ContentUploader question={data} onChange={onInputChange} />
              <ProblemForm question={data} onChange={onInputChange} />
              <ExplanationForm question={data} onChange={onInputChange} />
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Answers test={test} question={data} setQuestion={setData} />
          </div>
        </div>
      )}
    </Modal>
  );
}
