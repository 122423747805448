import { useState } from "react";
import { Form, Input, Divider } from "antd";
import Fade from "react-reveal/Fade";
import Details from "./details";
import Extra from "./extra";
import Editor from "./editor";
import Uploader from "./uploader";

export default function CompanyForm({ company, setCompany }) {
  return (
    <Fade duration={600} distance="5px" bottom>
      <div className="card">
        <div className="card-content">
          <span className="block w-full mx-auto text-center text-xl font-bold">
            Edit company details.
          </span>
          <p className="block w-full max-w-md text-center mx-auto text-base my-2">
            Please provide company details before publishing.
          </p>

          <div className="grid grid-cols-12 gap-x-4 gap-y-2 my-10">
            <Details />
            <div className="col-span-12">
              <Divider className="mt-3">Description</Divider>
            </div>
            <div className="col-span-12">
              <Editor
                description={company.description}
                setDescription={(v) =>
                  setCompany({ ...company, description: v })
                }
              />
            </div>
            <div className="col-span-12">
              <Divider>Logo</Divider>
            </div>
            <div className="col-span-12">
              <Uploader
                logo={company.logo}
                setLogo={(v) => setCompany({ ...company, logo: v })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-5 mb-0">
        <div className="card-content">
          <span className="block w-full mx-auto text-center text-xl font-bold">
            Additional company details.
          </span>
          <p className="block w-full max-w-md text-center mx-auto text-base my-2">
            This is required for SEO-friendly company pages
          </p>
          <div className="grid grid-cols-12 gap-x-4 gap-y-2 my-10 mt-0">
            <div className="col-span-12">
              <Divider className="mt-3">Short Description</Divider>
            </div>
            <div className="col-span-12 mb-5">
              <Editor
                description={company?.meta?.short_description}
                setDescription={(v) =>
                  setCompany({
                    ...company,
                    meta: {
                      ...company?.meta,
                      short_description: v,
                    },
                  })
                }
              />
            </div>
            <div className="col-span-12">
              <Divider className="mt-3">Rich Description</Divider>
            </div>
            <div className="col-span-12 mb-5">
              <Editor
                description={company?.meta?.rich_description}
                setDescription={(v) =>
                  setCompany({
                    ...company,
                    meta: {
                      ...company?.meta,
                      rich_description: v,
                    },
                  })
                }
              />
            </div>
            <Extra />
          </div>
        </div>
      </div>
    </Fade>
  );
}
