import axios from "axios";
import config from "@config";

const API = config.static;

export const getImage = (file) =>
  axios
    .get(`${API}/img/${file}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const uploadImage = (data) => {
  const form = new FormData();
  form.append("file", data);

  return axios
    .post(`${API}/upload/img`, form, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
};
