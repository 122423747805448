import { Input, Radio, Upload, message, Image, Button } from "antd";
import classnames from "classnames";
import * as Icons from "@ant-design/icons";
import * as API from "@api";
import { Fragment, useEffect } from "react";
import config from "@config";

export default function QuestionContent({ question, onChange }) {
  return (
    <div className="col-span-12">
      <span className="block font-medium text-xs text-slate-600 mb-1">
        Question Problem
      </span>
      <Input.TextArea
        onChange={(e) =>
          onChange({
            key: ["problem"],
            value: e.target.value,
          })
        }
        value={question.problem}
        rows={3}
        placeholder="Enter question problem"
      />
    </div>
  );
}
