import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layouts, Loading } from "@components";
import { message, Form, Input } from "antd";
import * as API from "@api";
import Sidebar from "./sidebar";
import CompanyForm from "@components/company/form";

export default function EditCompany() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    (async () => {
      const _res = await API.Companies.getOne({ id });
      if (_res.error || !_res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setCompany(_res.data);
      setLoading(false);
    })();
  }, []);

  if (!company) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  const onSave = async (data) => {
    const { id, description, logo } = company;
    const short_description = company?.meta?.short_description || null;
    const rich_description = company?.meta?.rich_description || null;

    if (!description || !logo) {
      return message.error("Description and Logo are required.", 2);
    }

    setLoading(true);
    const _res = await API.Companies.update({
      ...data,
      id,
      description,
      logo,
      meta: {
        ...data?.meta,
        short_description,
        rich_description,
      },
    });
    setLoading(false);

    if (_res.error || !_res) {
      return message.error(
        "Unable to save company details, please try again later.",
        2
      );
    }

    return message.success("Company saved successfully", 1, () =>
      document.location.reload()
    );
  };

  return (
    <Layouts.AdminLayout
      breadcrumbs={[
        { title: "Companies", url: "/companies" },
        {
          title: company?.name || "Company",
          url: "/companies/edit/" + company?.id,
        },
      ]}
      title="Edit Company Details"
    >
      <div className="test-page">
        <Form
          onFinish={onSave}
          onFinishFailed={(err) =>
            err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
          }
          name="company"
          layout="vertical"
          initialValues={company}
        >
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-8 h-full">
              <CompanyForm
                setCompany={setCompany}
                company={company}
                loading={loading}
              />
            </div>
            <div className="col-span-12 lg:col-span-4">
              <Sidebar company={company} loading={loading} />
            </div>
          </div>
        </Form>
      </div>
    </Layouts.AdminLayout>
  );
}
