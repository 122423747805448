import { Layouts } from "@components";
import NewReview from "@components/reviews/new";

export default function NewReviewPage() {
  return (
    <Layouts.AdminLayout
      title="New Review"
      breadcrumbs={[
        { title: "Reviews", url: "/reviews" },
        { title: "New Review", url: "/reviews/new" },
      ]}
    >
      <div className="dashboard-page">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <div className="card">
              <div className="card-content">
                <NewReview />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts.AdminLayout>
  );
}
