import { Table, Tag, Button } from "antd";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      scroll={{ x: "max-content" }}
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          align: "left",
          render: (data) => (
            <span className="block text-xs text-slate-500 m-0">{data}</span>
          ),
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          align: "left",
          width: 200,
          render: (data) => (
            <span className="block text-sm font-medium m-0">{data}</span>
          ),
        },
        {
          title: "User",
          dataIndex: "user",
          key: "user",
          align: "center",
          render: (data) =>
            data && (
              <div>
                <span className="text-sm font-bold text-black block">
                  {data?.display_name}
                </span>
                <a
                  href={`/users/edit/${data.id}`}
                  className="text-xs text-slate-500 block"
                >
                  {data.email} (ID: {data.id})
                </a>
              </div>
            ),
        },
        {
          title: "Amount",
          dataIndex: "amount_paid",
          key: "amount_paid",
          align: "center",
          render: (data) => (
            <span className="text-base font-bold">
              {(data / 100).toFixed(2)}
            </span>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          align: "center",
          render: (data, all) => {
            if (data === "awaiting_payment")
              return <Tag color="orange">Awaiting Payment</Tag>;
            if (data === "cancelled") return <Tag color="red">Cancelled</Tag>;
            if (data === "paid") return <Tag color="green">Paid</Tag>;
          },
        },
        {
          title: "Recent Action",
          dataIndex: "updated_at",
          key: "updated_at",
          align: "center",
          render: (data) => moment(data).format("DD MMM YYYY"),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Button href={`/payments/edit/${all.id}`}>View</Button>
          ),
        },
      ]}
    />
  );
}
