import { List, message, Button, Divider, Badge, Tooltip } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import * as API from "@api";

export default function Users() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const _res = await API.Users.getAll({ limit: 5 });
      setLoading(false);

      if (_res.error || !_res) {
        return message.error("Error loading data", 2);
      }
      setData(_res?.data?.rows);
    })();
  }, []);

  const getRole = (role) => {
    switch (role) {
      case "admin":
        return "Admin";

      case "member":
        return "Self-registered Member";

      case "partner":
        return "Partner";

      case "student":
        return "Student";

      default:
        break;
    }
  };

  return (
    <div className="card h-full">
      <div className="card-content h-full">
        <h3 className="mt-0 mb-3 text-xl">Recent Registrations</h3>
        <List
          loading={loading}
          dataSource={data}
          renderItem={(user) => (
            <List.Item>
              <div className="pr-10">
                <span className="text-base font-medium flex items-center">
                  {user.display_name}
                </span>
                <span className="block text-sm text-slate-500 mb-2">
                  <a className="text-sm" href={`mailto:${user.email}`}>
                    {user.email}
                  </a>
                  <Divider type="vertical" />
                  {getRole(user.role)}
                </span>
                <span className="block text-xs text-slate-500">
                  Registered:{" "}
                  {moment(user?.created_at).format("DD MMM YYYY / HH:mm")}
                </span>
              </div>
              <Button className="my-3" href={`/users/edit/${user.id}`}>
                View
              </Button>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
