import { Input, InputNumber, Select } from "antd";

export default function Filters({ onChange }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-4">
        <InputNumber
          controls={false}
          placeholder="Filter by ID"
          className="w-full"
          onChange={(v) => onChange("id", v)}
        />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <InputNumber
          controls={false}
          placeholder="Filter by Amount"
          className="w-full"
          onChange={(v) => onChange("amount_paid", v * 100)}
        />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <Select
          placeholder="Filter by Status"
          className="w-full"
          onChange={(v) => onChange("status", v)}
          allowClear
        >
          <Select.Option value={"paid"}>Paid</Select.Option>
          <Select.Option value={"awaiting_payment"}>
            Awaiting Payment
          </Select.Option>
          <Select.Option value={"cancelled"}>Cancelled</Select.Option>
        </Select>
      </div>
      <div className="col-span-12 lg:col-span-6">
        <Input
          type="email"
          controls={false}
          placeholder="Filter by Email"
          className="w-full"
          onChange={(v) => onChange("email", v.target.value)}
        />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <Input
          controls={false}
          placeholder="Filter by Stripe Payment ID"
          className="w-full"
          onChange={(v) => onChange("stripe_payment_id", v.target.value)}
        />
      </div>
    </div>
  );
}
