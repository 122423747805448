import { Input, Radio, Upload, message, Image, Button } from "antd";
import classnames from "classnames";
import * as Icons from "@ant-design/icons";
import * as API from "@api";
import { Fragment, useEffect } from "react";
import config from "@config";

export default function AnswerContent({ answer, onChange }) {
  return [
    <div className="col-span-12 flex flex-col items-center justify-center">
      <span className="block font-medium text-xs text-slate-600 mb-1">
        Answer Content
      </span>
      <Radio.Group
        value={answer?.content?.type}
        onChange={(e) =>
          onChange({
            key: ["content"],
            value: {
              type: e.target.value,
              data: null,
            },
          })
        }
        defaultValue="text"
        className="my-1"
        size="small"
        optionType="button"
        buttonStyle="solid"
        options={[
          { label: "Text", value: "text" },
          { label: "Image", value: "image" },
        ]}
      />
    </div>,
    <div className="col-span-12">
      {answer?.content?.type === "text" && (
        <Input.TextArea
          onChange={(e) =>
            onChange({
              key: ["content", "data"],
              value: e.target.value,
            })
          }
          value={answer?.content?.data}
          rows={4}
          placeholder="Type answer content"
        />
      )}
      {answer?.content?.type === "image" && [
        answer?.content?.data && (
          <Fragment>
            <Button
              className="mb-2 mx-auto block"
              onClick={() =>
                onChange({
                  key: ["content", "data"],
                  value: null,
                })
              }
              danger
              type="primary"
              size="small"
              icon={<Icons.DeleteOutlined />}
            >
              Delete
            </Button>
            <div className="block w-full max-h-44 text-center">
              <Image
                className="w-full max-h-44 object-contain object-center mx-auto"
                src={`${config.static}${answer?.content?.data}`}
              />
            </div>
          </Fragment>
        ),
        <Upload.Dragger
          className={classnames({ hidden: answer?.content?.data })}
          multiple={false}
          showUploadList={false}
          name="img"
          accept="image/*"
          customRequest={async ({ onSuccess, onError, file }) => {
            try {
              const _res = await API.Static.uploadImage(file);

              if (!_res?.path) {
                throw new Error();
              }

              onChange({
                key: ["content", "data"],
                value: _res.path,
              });
              onSuccess();
            } catch (error) {
              onError();
              return message.error("Error uploading image", 2);
            }
          }}
        >
          <Icons.InboxOutlined
            style={{ fontSize: 33 }}
            className="text-neutral-700"
          />
          <span className="mt-1 block w-full text-center font-medium text-sm">
            Upload Image
          </span>
        </Upload.Dragger>,
      ]}
    </div>,
  ];
}
