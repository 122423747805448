import config from "@config";
import SignInForm from "./form/signin";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function Signin() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <div className="login-layout grid grid-cols-2 gap-0">
        <div
          className="login-bgr col-span-2 md:col-span-1"
          style={{
            background: "url('/img/login-bgr.png') center center no-repeat",
            backgroundSize: "cover",
          }}
        />
        <div className="login-content col-span-2 md:col-span-1">
          <a
            className="logo-signin hidden md:block"
            href={config.frontend}
            title="Go to Homepage - Aptitude Tests"
          >
            <img src="/img/logo-dark.svg" alt="Aptitude Tests" />
          </a>

          <SignInForm />
          <div>
            <p className="my-2 text-sm text-slate-500">
              &copy; 2017–{new Date().getFullYear()} Aptitude Tests |{" "}
              <a href={`${config.frontend}/terms-and-conditions`}>
                Terms &amp; Conditions
              </a>{" "}
              | <a href={`${config.frontend}/privacy-policy`}>Privacy Policy</a>
            </p>
            <small>
              This website uses cookies. If you do not want this website to
              save, access and handle cookies, please leave this page.
            </small>
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
}
