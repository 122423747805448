import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layouts, Loading } from "@components";
import { message, Form } from "antd";
import * as API from "@api";
import Steps from "./steps";
import Types from "./form/types";
import Details from "./form/details";
import Questions from "./form/questions";

export default function EditTest() {
  const { id } = useParams();
  const [stage, setStage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [test, setTest] = useState(null);

  useEffect(() => {
    (async () => {
      const _res = await API.Tests.getOne({ id });
      if (_res.error || !_res) {
        return message.error("Unable to load test, please try again later.", 2);
      }

      setTest(_res.data);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!loading) {
      setStage(1);
    }
  }, [test?.personality]);

  if (!test) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  const onTestSave = async (data) => {
    const { id, questions = [] } = test;

    if (!questions || questions.length === 0) {
      return message.error("No questions provided for this test", 2);
    }

    setLoading(true);
    const _res = await API.Tests.update({ ...data, questions, id });
    setLoading(false);

    if (_res.error || !_res) {
      return message.error("Unable to save test, please try again later.", 2);
    }

    return message.success("Test saved successfully", 2);
  };

  return (
    <Layouts.AdminLayout
      title="Edit Test"
      breadcrumbs={[
        { title: "Tests", url: "/tests" },
        { title: test?.name, url: `/tests/edit/${test.id}` },
      ]}
    >
      <div className="test-page">
        <Form
          onFinish={onTestSave}
          onFinishFailed={(err) =>
            err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
          }
          name="test"
          layout="vertical"
          initialValues={test}
        >
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-8 h-full">
              <Types
                visible={stage === 0}
                test={test}
                setTest={setTest}
                setStage={setStage}
              />
              <Details
                visible={stage === 1}
                test={test}
                setTest={setTest}
                setStage={setStage}
              />
              <Questions
                visible={stage === 2}
                test={test}
                setTest={setTest}
                setStage={setStage}
              />
            </div>
            <div className="col-span-12 lg:col-span-4">
              <Steps loading={loading} stage={stage} setStage={setStage} />
            </div>
          </div>
        </Form>
      </div>
    </Layouts.AdminLayout>
  );
}
