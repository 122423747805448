import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function SearchFilter(params) {
  return (
    <Input.Search
      prefix={<SearchOutlined />}
      placeholder="Search by name"
      enterButton="Search"
      allowClear
      onSearch={params.onChange}
    />
  );
}
