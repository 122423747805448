import { useState, useEffect } from "react";
import { message, Select } from "antd";
import * as API from "@api";

export default function ProductFilter(params) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const _res = await API.Products.getAll();
      setLoading(false);

      if (!_res || _res.error) {
        return message.error("Unable to load Products", 2);
      }

      setData(_res.data.rows);
    })();
  }, []);

  return (
    <Select
      loading={loading}
      className="w-full"
      placeholder="Filter by Product"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("product_id", v)}
    >
      {data.map((data) => (
        <Select.Option key={data.id} value={data.id}>
          {data.name}
        </Select.Option>
      ))}
    </Select>
  );
}
