import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/companies`;
const API_PUBLIC = `${config.api}/companies`;

// Get all companies from public route
export const getAllPublic = (params) =>
  axios
    .get(`${API_PUBLIC}/`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get all companies
export const getAll = (params) =>
  axios
    .get(`${API}/`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get company by slug
export const getBySlug = ({ slug, ...params }) =>
  axios
    .get(`${API}/slug/${slug}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Get company by id
export const getOne = (params) =>
  axios
    .get(`${API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const create = (data) =>
  axios
    .post(`${API}/`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const update = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
