import axios from "axios";
import config from "@config";

const AUTH_API = `${config.api}/auth`;
const API = `${config.api}/admin/users`;

// Get all users
export const getAll = (params) =>
  axios
    .get(`${API}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const getOne = ({ id }) =>
  axios
    .get(`${API}/id/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const resetPassword = (data) =>
  axios
    .post(`${AUTH_API}/password/reset`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const update = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
