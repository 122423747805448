import { useState } from "react";
import { Layout, Menu, PageHeader, Breadcrumb, Dropdown, Avatar } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icons from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

export default function AdminLayout(props) {
  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const selectedKeys = (alias) => {
    if (location?.pathname === "/") {
      return ["home"];
    }

    const loc = location.pathname.substr(1).split("/");
    return [loc[0]];
  };

  const getBreadcrumbs = () => {
    if (!props?.breadcrumbs) {
      return null;
    }

    return (
      <Breadcrumb className="w-full mt-1 mb-5">
        <Breadcrumb.Item href="/">
          <Icons.HomeOutlined />
        </Breadcrumb.Item>
        {props.breadcrumbs.map((item, index) => (
          <Breadcrumb.Item key={index} href={item.url}>
            {item.title}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        // collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <div className="logo-top">
          <img src="/img/logo-short.svg" />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["dashboard"]}
          selectedKeys={selectedKeys() || ["dashboard"]}
          mode="inline"
        >
          <Menu.Item key="home" icon={<Icons.DashboardOutlined />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="companies" icon={<Icons.BankOutlined />}>
            <Link to="/companies">Companies</Link>
          </Menu.Item>
          <Menu.Item key="orders" icon={<Icons.ShoppingCartOutlined />}>
            <Link to="/orders">Orders</Link>
          </Menu.Item>
          <Menu.Item key="payments" icon={<Icons.PoundOutlined />}>
            <Link to="/payments">Payments</Link>
          </Menu.Item>
          <Menu.Item key="users" icon={<Icons.TeamOutlined />}>
            <Link to="/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="tests" icon={<Icons.ContainerOutlined />}>
            <Link to="/tests">Tests</Link>
          </Menu.Item>
          <Menu.Item key="products" icon={<Icons.FileTextOutlined />}>
            <Link to="/products">Products</Link>
          </Menu.Item>
          <Menu.Item key="reviews" icon={<Icons.StarOutlined />}>
            <Link to="/reviews">Reviews</Link>
          </Menu.Item>
          <Menu.Item key="errors" icon={<Icons.BugOutlined />}>
            <Link to="/errors">Errors</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background flex justify-end items-center p-5">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  className="w-32"
                  icon={<Icons.LogoutOutlined />}
                  danger
                  key="logout"
                >
                  <Link className="transition-none" to="/logout">
                    Logout
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <Avatar icon={<Icons.UserOutlined />} />
          </Dropdown>
        </Header>
        <Content className="container max-content">
          <PageHeader
            className="w-full p-0 m-0"
            onBack={() => navigate(-1, { replace: false })}
            title={props?.title}
            subTitle={props?.subtitle}
            extra={props?.extra}
          />
          {getBreadcrumbs()}
          {props.children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Aptitude Tests &copy; 2017 – {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
}
