import React, { createContext, useContext, useEffect, useState } from "react";
import * as API from "@api";
import * as R from "ramda";
import { message } from "antd";

const AuthContext = createContext({
  currentUser: null,
  login: () => Promise,
  logout: () => Promise,
  loading: false,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkAuth() {
      setLoading(true);
      const user = await API.Auth.getAuth();

      if (user.data && !user.error) {
        setCurrentUser(user.data);
      }

      setLoading(false);
    }

    return checkAuth();
  }, []);

  async function login({ email, password, token = null }) {
    if (!token) {
      return message.error(
        "Error verifying reCaptcha, please try again or refresh this page",
        2
      );
    }

    setLoading(true);
    const user = await API.Auth.signin({ email, password, token });
    setLoading(false);

    if (
      !user ||
      user.error ||
      R.pathOr(null, ["data", "user", "role"], user) !== "admin"
    ) {
      return message.error("Invalid email or password, please try again", 2);
    }

    setCurrentUser({
      email: R.pathOr(null, ["data", "user", "email"], user),
      name: R.pathOr(null, ["data", "user", "display_name"], user),
      id: R.pathOr(null, ["data", "user", "id"], user),
      role: R.pathOr(null, ["data", "user", "role"], user),
      provider: "local",
    });
  }

  async function logout() {
    setLoading(true);
    await API.Auth.logout();
    setCurrentUser(null);
    setLoading(false);
  }

  const value = {
    currentUser,
    login,
    logout,
    loading,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
