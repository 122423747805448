import { InputNumber, Select } from "antd";

export default function Filters({ onChange }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-3">
        <InputNumber
          controls={false}
          placeholder="Filter by ID"
          className="w-full"
          onChange={(v) => onChange("id", v)}
        />
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Select
          placeholder="Filter by Role"
          className="w-full"
          onChange={(v) => onChange("role", v)}
          allowClear
        >
          <Select.Option value={"admin"}>Admin</Select.Option>
          <Select.Option value={"member"}>Member</Select.Option>
          <Select.Option value={"student"}>Student</Select.Option>
          <Select.Option value={"partner"}>Partner</Select.Option>
        </Select>
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Select
          placeholder="Filter by Status"
          className="w-full"
          onChange={(v) => onChange("blocked", v)}
          allowClear
        >
          <Select.Option value={1}>Blocked</Select.Option>
          <Select.Option value={0}>Active</Select.Option>
        </Select>
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Select
          placeholder="Filter by Sign Up Method"
          className="w-full"
          onChange={(v) => onChange("signup_method", v)}
          allowClear
        >
          <Select.Option value="firebase">Firebase</Select.Option>
          <Select.Option value="local">Local</Select.Option>
        </Select>
      </div>
    </div>
  );
}
