import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layouts, Loading } from "@components";
import { message, Form } from "antd";
import * as API from "@api";
import Sidebar from "./sidebar";
import PaymentForm from "@components/payment/form";

export default function EditPayment() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    (async () => {
      const _res = await API.Payments.getOne({ id });
      if (_res.error || !_res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      _res.data.amount_paid = _res?.data?.amount_paid / 100;

      setPayment(_res.data);
      setLoading(false);
    })();
  }, []);

  if (!payment) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  const onSave = async (data) => {
    setLoading(true);
    const _res = await API.Payments.update({
      ...data,
      id: payment.id,
      stripe_payment_receipt: data?.stripe_payment_receipt || null,
      card_type: data?.card_type || null,
      card_last_digits: data?.card_last_digits || null,
      amount_paid: data?.amount_paid * 100,
    });
    setLoading(false);
    if (_res.error || !_res) {
      return message.error(
        "Unable to save payment details, please try again later.",
        2
      );
    }
    return message.success("Payment updated successfully", 1, () =>
      document.location.reload()
    );
  };

  return (
    <Layouts.AdminLayout
      title="Edit Payment"
      breadcrumbs={[
        { title: "Payments", url: "/payments" },
        { title: `Payment ${payment.id}`, url: `/payments/edit/${payment.id}` },
      ]}
    >
      <div className="test-page">
        <Form
          onFinish={onSave}
          onFinishFailed={(err) =>
            err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
          }
          name="payment"
          layout="vertical"
          initialValues={payment}
        >
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-8 h-full">
              <PaymentForm
                setPayment={setPayment}
                payment={payment}
                loading={loading}
              />
            </div>
            <div className="col-span-12 lg:col-span-4">
              <Sidebar payment={payment} loading={loading} />
            </div>
          </div>
        </Form>
      </div>
    </Layouts.AdminLayout>
  );
}
