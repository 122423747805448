import axios from "axios";
import qs from "qs";
import * as Auth from "./auth";
import * as Orders from "./orders";
import * as Users from "./users";
import * as Errors from "./errors";
import * as Products from "./products";
import * as Tests from "./tests";
import * as Static from "./static";
import * as Companies from "./companies";
import * as Payments from "./payments";
import * as Reviews from "./reviews";

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

export {
  Auth,
  Orders,
  Users,
  Errors,
  Products,
  Tests,
  Static,
  Companies,
  Payments,
  Reviews,
};
