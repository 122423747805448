import { Modal, Input, Select, InputNumber } from "antd";
import { Loading } from "@components";
import AnswerContent from "./content";
import * as R from "ramda";

export default function EditAnswerModal({
  onSave,
  question,
  answer,
  onInputChange,
  onClose,
}) {
  const answerCorrectOptions = () => {
    switch (question.type) {
      // Radio & Multiple Choice Select Options
      case 1:
      case 2:
      case 3:
        return (
          <Select
            className="w-full"
            onChange={(value) => {
              onInputChange({
                key: ["correct"],
                value,
              });
            }}
            value={answer?.correct}
            placeholder="This answer is …"
          >
            <Select.Option
              disabled={
                question.type !== 2 &&
                R.find(R.propEq("correct", 1), question.answers)
              }
              value={1}
            >
              Correct
            </Select.Option>
            <Select.Option value={0}>Incorrect</Select.Option>
          </Select>
        );

      // Best and Worst response
      case 4:
        return (
          <Select
            className="w-full"
            onChange={(value) => {
              onInputChange({
                key: ["correct"],
                value,
              });
            }}
            allowClear
            value={answer?.correct}
            placeholder="This is …"
            defaultValue={null}
          >
            <Select.Option
              disabled={R.find(R.propEq("correct", 1), question.answers)}
              value={1}
            >
              Worst Response
            </Select.Option>
            <Select.Option
              disabled={R.find(R.propEq("correct", 2), question.answers)}
              value={2}
            >
              Best Response
            </Select.Option>
            <Select.Option value={0}>Neither</Select.Option>
          </Select>
        );

      // Least-to-most effective response
      case 5:
        return (
          <InputNumber
            className="w-full"
            onChange={(value) => {
              onInputChange({
                key: ["correct"],
                value,
              });
            }}
            value={answer?.correct || 0}
            controls={false}
            min={0}
            max={5}
            placeholder="Type weight (0-5)"
          />
        );

      default:
        break;
    }
  };

  return (
    <Modal
      visible={!!answer}
      destroyOnClose
      title="Edit Answer"
      maskClosable={false}
      onCancel={onClose}
      okText="Done"
      onOk={onSave}
      cancelText="Cancel"
      width={600}
      centered
      maskStyle={{ background: "rgba(0,0,0,.8)" }}
    >
      {!answer ? (
        <Loading />
      ) : (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <span className="block font-medium text-xs text-slate-600 mb-1">
              ID
            </span>
            <Input value={answer.id} disabled />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <span className="block font-medium text-xs text-slate-600 mb-1">
              {question.type === 5 ? "Response Weight (0-5)" : "Correctness"}
            </span>
            {answerCorrectOptions()}
          </div>
          <AnswerContent answer={answer} onChange={onInputChange} />
        </div>
      )}
    </Modal>
  );
}
