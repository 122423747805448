import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layouts, Loading } from "@components";
import { message, Form } from "antd";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import * as API from "@api";
import Sidebar from "./sidebar";
import UserForm from "@components/user/form";
import config from "@config";

export default function EditUser() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      const _res = await API.Users.getOne({ id });
      if (_res.error || !_res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setUser(_res.data);
      setLoading(false);
    })();
  }, []);

  if (!user) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  const onSave = async (data) => {
    delete data?.password;
    delete data?.last_login;

    setLoading(true);
    const _res = await API.Users.update({ ...data, id: user.id });
    setLoading(false);

    if (_res.error || !_res) {
      return message.error(
        "Unable to save user details, please try again later.",
        2
      );
    }

    return message.success("User updated successfully", 1, () =>
      document.location.reload()
    );
  };

  return (
    <Layouts.AdminLayout
      title="Edit User Details"
      breadcrumbs={[
        { title: "Users", url: "/users" },
        { title: `${user?.display_name}`, url: `/users/edit/${user?.id}` },
      ]}
    >
      <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
        <div className="test-page">
          <Form
            onFinish={onSave}
            onFinishFailed={(err) =>
              err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
            }
            name="user"
            layout="vertical"
            initialValues={user}
          >
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 lg:col-span-8 h-full">
                <UserForm setPayment={setUser} user={user} loading={loading} />
              </div>
              <div className="col-span-12 lg:col-span-4">
                <Sidebar user={user} loading={loading} />
              </div>
            </div>
          </Form>
        </div>
      </GoogleReCaptchaProvider>
    </Layouts.AdminLayout>
  );
}
