import { Table, Tag, Button } from "antd";
import { Link } from "react-router-dom";
import { StarFilled } from "@ant-design/icons";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      scroll={{ x: "max-content" }}
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          align: "left",
          render: (data) => (
            <span className="block text-xs text-slate-500 m-0">{data}</span>
          ),
        },
        {
          title: "User",
          dataIndex: "display_name",
          key: "display_name",
          align: "left",
          width: 120,
          render: (data, all) => (
            <div>
              <span className="block text-sm font-medium m-0">{data}</span>
            </div>
          ),
        },
        {
          title: "Order",
          dataIndex: "order",
          key: "order",
          align: "center",
          render: (data) =>
            data && (
              <Link to={`/orders/${data.id}`}>
                <span className="m-0 text-base text-center font-normal">
                  {data.description}
                </span>
              </Link>
            ),
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          align: "center",
          width: 200,
          render: (title, { content }) => (
            <div>
              <span className="block text-base text-center text-black font-medium">
                {title}
              </span>
              <span className="block text-sm text-center text-neutral-600 font-normal">
                {content}
              </span>
            </div>
          ),
        },
        {
          title: "Score",
          dataIndex: "score",
          key: "score",
          align: "center",
          render: (data) => {
            let stars = [];

            for (let i = 0; i < data.score; i++) {
              stars.push(<StarFilled className="text-orange-400 text-lg" />);
            }

            return (
              <div>
                {stars}
                <span className="block text-sm font-normal text-neutral-800">
                  {data}/5
                </span>
              </div>
            );
          },
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Link to={`/reviews/edit/${all.id}`}>
              <Button>View</Button>
            </Link>
          ),
        },
      ]}
    />
  );
}
