import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layouts, Loading } from "@components";
import { message, Form } from "antd";
import * as API from "@api";
import Sidebar from "./sidebar";
import OrderForm from "@components/order/form";
import moment from "moment";

export default function EditOrder() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    (async () => {
      const [_order, _products] = await Promise.all([
        await API.Orders.getOne({ id }),
        await API.Products.getAll({ limit: 100 }),
      ]);

      if (_order.error || _products.error) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      _order.data.expires_at = moment(_order.data.expires_at) || null;
      _order.data.created_at = moment(_order.data.created_at) || null;
      _order.data.updated_at = moment(_order.data.updated_at) || null;

      setOrder(_order.data);
      setProducts(_products.data.rows);
      setLoading(false);
    })();
  }, []);

  if (!order) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  const onSave = async (data) => {
    delete data?.created_at;
    delete data?.updated_at;

    setLoading(true);
    const _res = await API.Orders.update({
      ...data,
      id: order.id,
      company_id: data?.company_id || null,
      card_type: data?.card_type || null,
      card_last_digits: data?.card_last_digits || null,
      amount_paid: data?.amount_paid * 100,
      expires_at: data.expires_at.format(),
    });
    setLoading(false);
    if (_res.error || !_res) {
      return message.error(
        "Unable to save order details, please try again later.",
        2
      );
    }
    return message.success("Order updated successfully", 1, () =>
      document.location.reload()
    );
  };

  return (
    <Layouts.AdminLayout
      title="Edit Order"
      breadcrumbs={[
        { title: "Orders", url: "/orders" },
        { title: `Order ${order.id}`, url: `/order/edit/${order.id}` },
      ]}
    >
      <div className="test-page">
        <Form
          onFinish={onSave}
          onFinishFailed={(err) =>
            err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
          }
          name="order"
          layout="vertical"
          initialValues={order}
        >
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-8 h-full">
              <OrderForm
                setOrder={setOrder}
                order={order}
                products={products}
                loading={loading}
              />
            </div>
            <div className="col-span-12 lg:col-span-4">
              <Sidebar order={order} loading={loading} />
            </div>
          </div>
        </Form>
      </div>
    </Layouts.AdminLayout>
  );
}
