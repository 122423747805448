import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Select,
} from "antd";
import Fade from "react-reveal/Fade";
import * as Icons from "@ant-design/icons";
import * as API from "@api";

export default function Details({ test, setTest, setStage, visible = false }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      const _res = await API.Products.getAll();

      if (_res.error || !_res) {
        return message.error(
          "Unable to load products, please try again later.",
          2
        );
      }

      setProducts(_res.data.rows);
    })();
  }, []);

  return (
    <Fade spy={visible} duration={600} distance="5px" bottom>
      <div hidden={!visible} className="card h-full">
        <div className="card-content">
          <span className="block w-full mx-auto text-center text-xl font-bold">
            Fill in test details.
          </span>
          <p className="block w-full max-w-md text-center mx-auto text-base my-2">
            Please provide test details before going to the next step.
          </p>

          <div className="w-full grid grid-cols-12 gap-x-4 gap-y-2 mt-10">
            <div className="col-span-12 lg:col-span-6">
              <Form.Item
                name="product_id"
                className="w-full"
                label="Product"
                tooltip="The test will be included in selected product package."
                rules={[
                  {
                    required: true,
                    message: "Please select product package",
                  },
                ]}
              >
                <Select
                  placeholder="Select product"
                  options={products.map((p) => {
                    return {
                      label: p.name,
                      value: p.id,
                      disabled: p.type === "bundle",
                    };
                  })}
                />
              </Form.Item>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <Form.Item
                name="active"
                className="w-full"
                label="Active?"
                tooltip="The test will be available for users to take."
                rules={[
                  {
                    required: true,
                    message: "Please select test status",
                  },
                ]}
              >
                <Select placeholder="Select status">
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-span-12">
              <Form.Item
                name="name"
                className="w-full"
                label="Name"
                tooltip="Public test name. Will be visible to test-takers."
                rules={[
                  {
                    required: true,
                    message: "Please enter test name",
                  },
                ]}
              >
                <Input placeholder="Enter test name" />
              </Form.Item>
            </div>
            <div className="col-span-12">
              <Form.Item
                name="description"
                className="w-full"
                label="Description"
                tooltip="Public test description. Will be visible to test-takers."
              >
                <Input.TextArea rows={6} placeholder="Enter test description" />
              </Form.Item>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Form.Item
                name="pass_score"
                className="w-full"
                label="Pass Score"
                tooltip="Score needed to pass this test."
                rules={[
                  {
                    required: true,
                    message: "Please enter test pass score",
                  },
                ]}
              >
                <InputNumber
                  className="w-full"
                  controls={false}
                  min={10}
                  max={100}
                  placeholder="Enter score"
                  addonAfter="%"
                />
              </Form.Item>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Form.Item
                name="negative_marking"
                className="w-full"
                label="Negative Marking"
                tooltip="Allow negative marking for questions? If enabled, test takers will lose points for wrong answers."
              >
                <Select placeholder="Select">
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Form.Item
                name="time"
                className="w-full"
                label="Time Limit"
                tooltip="Test time limit in minutes. Leave 0 if you want to enable time limit per question."
                rules={[
                  {
                    required: true,
                    message: "Please add time limit for test",
                  },
                ]}
              >
                <InputNumber
                  className="w-full"
                  controls={false}
                  min={0}
                  placeholder="Enter score"
                  addonAfter="min"
                />
              </Form.Item>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Form.Item
                name="question_time"
                className="w-full"
                label="Question Time"
                tooltip="Time limit for each question in minutes."
              >
                <InputNumber
                  className="w-full"
                  controls={false}
                  min={0}
                  placeholder="Enter score"
                  addonAfter="min"
                />
              </Form.Item>
            </div>
            <div className="col-span-12">
              <Divider className="mt-2 mb-4">Compatibility</Divider>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Form.Item
                name="shl_compatible"
                className="w-full"
                label="SHL"
                tooltip="Enable SHL compatibility for this test?"
              >
                <Select placeholder="Select">
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Form.Item
                name="kenexa_compatible"
                className="w-full"
                label="Kenexa"
                tooltip="Enable Kenexa compatibility for this test?"
              >
                <Select placeholder="Select">
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Form.Item
                name="talent_q_compatible"
                className="w-full"
                label="Talent Q"
                tooltip="Enable Talent Q compatibility for this test?"
              >
                <Select placeholder="Select">
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <Form.Item
                name="cubiks_compatible"
                className="w-full"
                label="Cubiks"
                tooltip="Enable Cubiks compatibility for this test?"
              >
                <Select placeholder="Select">
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <Divider />
          <Button
            onClick={() => setStage(2)}
            type="primary"
            className="mx-auto block"
          >
            Next <Icons.ArrowRightOutlined />
          </Button>
        </div>
      </div>
    </Fade>
  );
}
