import { Table, Tag, Button } from "antd";
import * as Icons from "@ant-design/icons";
import { Link } from "react-router-dom";
import config from "@config";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      scroll={{ x: "max-content" }}
      columns={[
        {
          dataIndex: "logo",
          key: "logo",
          align: "left",
          width: 180,
          render: (data) =>
            data ? (
              <img
                className="block object-contain object-left max-h-6 w-48"
                alt="Company Logo"
                src={`${config.static}${data}`}
              />
            ) : (
              <Icons.EyeInvisibleOutlined
                title="No Image"
                className="text-rose-500 text-2xl m-0 leading-none"
              />
            ),
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          align: "left",
          width: 300,
          render: (data) => (
            <span className="block text-sm font-medium mb-1">{data}</span>
          ),
        },
        {
          title: "Published",
          dataIndex: "published",
          key: "published",
          align: "center",
          width: 140,
          render: (data) => (data ? "Yes" : "No"),
        },
        {
          title: "Last Edit",
          dataIndex: "updated_at",
          key: "updated_at",
          align: "center",
          render: (data) => moment(data).format("DD MMM YYYY"),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Link to={`/companies/edit/${all.id}`}>
              <Button>View</Button>
            </Link>
          ),
        },
      ]}
    />
  );
}
