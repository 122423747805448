import Fade from "react-reveal/Fade";
import { Steps, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";

export default function Stages({ test, stage, setStage, loading }) {
  return (
    <Fade delay={200} duration={600} distance="5px" bottom>
      <div className="card">
        <div className="card-content">
          <h2 className="text-lg mb-5">{test?.name}</h2>
          <Steps
            onChange={(idx) => setStage(idx)}
            className="m-0"
            direction="vertical"
            current={stage}
          >
            <Steps.Step
              title="Test Type"
              description="Select desired test type."
            />
            <Steps.Step title="Details" description="Enter test details." />
            <Steps.Step
              title="Questions"
              description="Add questions and answers."
            />
          </Steps>
          <Button
            loading={loading}
            type="primary"
            className="mt-5"
            icon={<SaveOutlined />}
            htmlType="submit"
          >
            Save Test
          </Button>
        </div>
      </div>
    </Fade>
  );
}
