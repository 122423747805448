import { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";
import { useAuth } from "@contexts";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import config from "@config";

export default function SignInForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { loading, login, currentUser } = useAuth();

  async function handleSignin(data) {
    data.token = await executeRecaptcha();
    await login(data);
  }

  if (currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className="mt-10 mb-10">
      <h1>Sign In</h1>

      <Form
        tabIndex={1}
        className="login-form"
        name="signin"
        size="large"
        initialValues={{ remember: true }}
        onFinish={handleSignin}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              validateTrigger: "onSubmit",
              message: "Invalid email format",
            },
          ]}
        >
          <Input
            disabled={loading}
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            disabled={loading}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="admin-login-form-button"
            loading={loading}
            block
          >
            Sign In
          </Button>
        </Form.Item>
      </Form>
      <span className="block text-sm text-slate-500 max-w-sm mt-7">
        Please proceed to <a href={`${config.member}`}>Member's Dashboard</a> if
        you want to join or sign in for practice.
      </span>
    </div>
  );
}
