import { useState, useEffect } from "react";
import { Button, message, Divider } from "antd";
import { Layouts } from "@components";
import * as Icons from "@ant-design/icons";
import * as API from "@api";
import BaseFilters from "./filters";
import SearchFilter from "./filters/search";
import Table from "./table";
import Fade from "react-reveal/Fade";
import debounce from "debounce";

export default function Orders() {
  const [products, setProducts] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({
    id: undefined,
    created_at: {
      from: undefined,
      to: undefined,
    },
    products: [],
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    (async () => {
      const _res = await API.Products.getAll({ limit: 100 });
      if (_res.error || !_res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setProducts(_res.data.rows);
    })();
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination) {
    setTableLoading(true);
    const _res = await API.Orders.getAll({
      filter,
      s: search,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setTableLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  onFilterChange = debounce(onFilterChange, 500);

  return (
    <Layouts.AdminLayout
      title="Orders"
      breadcrumbs={[{ title: "Orders", url: "/orders" }]}
    >
      <div className="dashboard-page">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <Fade delay={200} duration={600} distance="5px" bottom>
              <div className="card">
                <div className="card-content">
                  <div className="grid grid-cols-12 gap-4 mb-10">
                    <div className="col-span-12">
                      <BaseFilters
                        products={products}
                        onChange={onFilterChange}
                      />
                    </div>
                    <div className="col-span-12">
                      <SearchFilter onChange={onSearch} />
                    </div>
                  </div>

                  <Table
                    onChange={getTableData}
                    pagination={pagination}
                    dataSource={tableData}
                    loading={tableLoading}
                    products={products}
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layouts.AdminLayout>
  );
}
