import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Loading } from "@components";
import { useAuth } from "@contexts";

export default function Logout() {
  const { currentUser, logout } = useAuth();

  useEffect(() => {
    async function doLogout() {
      await logout();
    }

    doLogout();
  }, []);

  if (!currentUser) {
    return <Navigate to="/signin" />;
  }

  return <Loading />;
}
