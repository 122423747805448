import axios from "axios";
import config from "@config";

const API = `${config.api}/auth`;

// Returns current user auth details
export const getAuth = () =>
  axios
    .get(`${API}/`, {
      withCredentials: true,
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Sign In user
export const signin = (data) =>
  axios
    .post(`${API}/signin`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Sign In user with oauth
export const signinOAuth = (data) =>
  axios
    .post(`${API}/signin/oauth`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Logout user
export const logout = (data) =>
  axios
    .post(`${API}/logout`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
