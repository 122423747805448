import { Table, Tag, Button } from "antd";
import * as R from "ramda";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      scroll={{ x: "max-content" }}
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          align: "left",
          render: (data) => (
            <span className="block text-xs text-slate-500 m-0">{data}</span>
          ),
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          align: "left",
          width: 200,
          render: (data) => (
            <span className="block text-sm font-medium m-0 mb-2">{data}</span>
          ),
        },
        {
          title: "User",
          dataIndex: "user",
          key: "user",
          align: "left",
          render: (data) =>
            data && (
              <div>
                <span className="text-sm font-bold text-black block">
                  {data?.display_name}
                </span>
                <a
                  href={`/users/edit/${data.id}`}
                  className="text-xs text-slate-500 block"
                >
                  {data.email} (ID: {data.id})
                </a>
              </div>
            ),
        },
        {
          title: "Status",
          dataIndex: "payment",
          key: "payment",
          align: "center",
          render: (data) =>
            data ? (
              <Tag
                color={
                  data.status === "paid"
                    ? "green"
                    : data.status === "cancelled"
                    ? "red"
                    : "orange"
                }
                className="capitalize m-0"
              >
                {R.replace("_", " ", data.status)}
              </Tag>
            ) : (
              <Tag className="m-0">No Payment</Tag>
            ),
        },
        {
          title: "Expires At",
          dataIndex: "expires_at",
          key: "expires_at",
          align: "center",
          render: (data, all) => {
            if (moment(data).isBefore(moment()))
              return (
                <Tag className="m-0" color="red">
                  Expired
                </Tag>
              );
            return (
              <Tag className="m-0">{moment(data).format("DD MMM YYYY")}</Tag>
            );
          },
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          key: "created_at",
          align: "center",
          render: (data) => moment(data).format("DD MMM YYYY"),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Button href={`/orders/edit/${all.id}`}>View</Button>
          ),
        },
      ]}
    />
  );
}
