import { Layouts } from "@components";
import Orders from "./cards/orders";
import Users from "./cards/users";
import Reports from "./cards/reports";
import Fade from "react-reveal/Fade";

export default function Dashboard(params) {
  return (
    <Layouts.AdminLayout
      title="Dashboard"
      subtitle="View recent activity on Website"
    >
      <div className="dashboard-page mt-5">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <Fade delay={200} duration={600} distance="5px" bottom>
              <Orders />
            </Fade>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Fade delay={200} duration={600} distance="5px" bottom>
              <Users />
            </Fade>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Fade delay={200} duration={600} distance="5px" bottom>
              <Reports />
            </Fade>
          </div>
        </div>
      </div>
    </Layouts.AdminLayout>
  );
}
