import { LoadingOutlined } from "@ant-design/icons";
import classnames from "classnames";

export default function Loading({ compact = false }) {
  return (
    <div className={classnames("loading-global", { compact })}>
      <LoadingOutlined style={{ fontSize: 40 }} />
    </div>
  );
}
