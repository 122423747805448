import { useState } from "react";
import { Form, Input, Divider } from "antd";
import Fade from "react-reveal/Fade";
import Details from "./details";
import UserDetails from "./user";

export default function PaymentForm({ payment }) {
  return (
    <Fade duration={600} distance="5px" bottom>
      <div className="card h-full">
        <div className="card-content">
          <span className="block w-full mx-auto text-center text-xl font-bold">
            Edit payment details.
          </span>
          <p className="block w-full max-w-md text-center mx-auto text-base my-2">
            Provide payment details before saving.
          </p>

          <div className="grid grid-cols-12 gap-x-4 gap-y-2 my-10">
            <Details />
            <div className="col-span-12">
              <Divider>User Details</Divider>
            </div>
            <UserDetails payment={payment} />
          </div>
        </div>
      </div>
    </Fade>
  );
}
