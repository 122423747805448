module.exports = {
  env: process.env.NODE_ENV || "development",
  port: process.env.PORT || process.env.REACT_APP_PORT || "3001",
  api: process.env.REACT_APP_API_ENDPOINT,
  static: process.env.REACT_APP_STATIC_ENDPOINT,
  frontend: process.env.REACT_APP_FRONTEND_ENDPOINT,
  member: process.env.REACT_APP_MEMBER_ENDPOINT,
  basepath: process.env.REACT_APP_BASEPATH,
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  imgproxy: {
    baseUrl: process.env.REACT_APP_IMGPROXY_BASEURL,
    key: process.env.REACT_APP_IMGPROXY_KEY,
    salt: process.env.REACT_APP_IMGPROXY_SALT,
    signatureSize: 32,
    encode: true,
  },
};
