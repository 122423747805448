import { message, Select, Spin, Form } from "antd";
import * as API from "@api";
import { useState } from "react";
import debounce from "debounce";

export default function OrdersFilter() {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  let fetchList = async (value) => {
    setOptions([]);
    setFetching(true);
    const data = await API.Orders.getAll({
      limit: 10,
      filter: {
        id: value,
      },
    });

    if (!data || data?.error) {
      return message.error(
        "Unable to perform search, please check your internet connection",
        3
      );
    }

    setOptions(
      data?.data?.rows?.map(({ id, description, user }) => ({
        value: id,
        label: `Order No. ${id}, (${user?.email}): ${description}`,
      }))
    );

    setFetching(false);
  };

  fetchList = debounce(fetchList, 200);

  return (
    <Form.Item label="Order ID" name="order_id">
      <Select
        showSearch
        className="w-full"
        allowClear
        placeholder="Star typing to search"
        filterOption={false}
        onSearch={fetchList}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
      />
    </Form.Item>
  );
}
