import { Input, InputNumber, Select, DatePicker } from "antd";
import moment from "moment";

export default function Filters({ products = [], onChange }) {
  moment.locale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-4">
        <InputNumber
          controls={false}
          placeholder="Filter by ID"
          className="w-full"
          onChange={(v) => onChange("id", v)}
        />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <Select
          placeholder="Filter by Product"
          className="w-full"
          onChange={(v) => onChange("products", v)}
          allowClear
          mode="multiple"
          options={products.map((p) => {
            return {
              label: p.name,
              value: p.id,
              hidden: p.linked_products,
            };
          })}
        />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <DatePicker.RangePicker
          locale="en"
          className="w-full"
          format="DD MMM YYYY"
          onChange={(dates) =>
            onChange(
              "created_at",
              dates
                ? {
                    from: moment(dates[0]).format("YYYY-MM-DD") || undefined,
                    to: moment(dates[1]).format("YYYY-MM-DD") || undefined,
                  }
                : { from: undefined, to: undefined }
            )
          }
        />
      </div>
    </div>
  );
}
