import { Table, Tag, Button } from "antd";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      scroll={{ x: "max-content" }}
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          align: "left",
          render: (data) => (
            <span className="block text-xs text-slate-500 m-0">{data}</span>
          ),
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          align: "left",
          width: 200,
          render: (data) => (
            <span className="block text-sm font-medium m-0">{data}</span>
          ),
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          align: "center",
          render: (data) => <Tag className="m-0 capitalize">{data}</Tag>,
        },
        {
          title: "Status",
          dataIndex: "active",
          key: "active",
          align: "center",
          render: (data) => (
            <Tag className="m-0 capitalize">
              {data ? "Published" : "Inactive"}
            </Tag>
          ),
        },
        {
          title: "Has Linked Products",
          dataIndex: "linked_products",
          key: "linked_products",
          align: "center",
          render: (data) =>
            data ? (
              <Tag className="m-0">Yes</Tag>
            ) : (
              <Tag className="m-0">No</Tag>
            ),
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          align: "center",
          render: (data) => (
            <span className="text-base font-bold">
              £{(data / 100).toFixed(2)}
            </span>
          ),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Button href={`/products/edit/${all.id}`} disabled>
              View
            </Button>
          ),
        },
      ]}
    />
  );
}
