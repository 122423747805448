import { Form, Input, Select } from "antd";
import * as Icons from "@ant-design/icons";

export default function Details() {
  return [
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="name"
        className="w-full"
        label="Name"
        tooltip="The name of the company"
        rules={[
          {
            required: true,
            message: "Please enter company name.",
          },
        ]}
      >
        <Input placeholder="Enter company name" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="slug"
        className="w-full"
        label="Slug"
        tooltip="Slug is generated automatically"
      >
        <Input placeholder="Generated automatically" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="url"
        className="w-full"
        label="Website"
        tooltip="Company's international website"
        rules={[
          {
            type: "url",
            required: true,
            message: "Please enter full URL with https://...",
          },
        ]}
      >
        <Input
          prefix={<Icons.LinkOutlined />}
          type="url"
          placeholder="Enter company website"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="wikipedia_url"
        className="w-full"
        label="Wiki URL"
        tooltip="URL to company's Wikipedia page"
        rules={[
          {
            type: "url",
            required: true,
            message: "Please enter full URL with https://...",
          },
        ]}
      >
        <Input
          prefix={<Icons.LinkOutlined />}
          type="url"
          placeholder="Enter company wiki URL"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="careers_url"
        className="w-full"
        label="Careers Website"
        tooltip="URL to company's careers page"
        rules={[
          {
            type: "url",
            required: true,
            message: "Please enter full URL with https://...",
          },
        ]}
      >
        <Input
          prefix={<Icons.LinkOutlined />}
          type="url"
          placeholder="Enter company careers URL"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="graduate_careers_url"
        className="w-full"
        label="Graduate Careers Website"
        tooltip="URL to company's graduate careers page"
        rules={[
          {
            type: "url",
            required: false,
            message: "Please enter full URL with https://...",
          },
        ]}
      >
        <Input
          prefix={<Icons.LinkOutlined />}
          type="url"
          placeholder="Enter company graduate careers URL"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12">
      <Form.Item
        name="hiring_stages"
        className="w-full"
        label="Hiring stages"
        help="Select Hiring Stages or type and press Enter"
        rules={[
          {
            type: "array",
            required: true,
            message: "Please enter hiring stages",
          },
        ]}
      >
        <Select mode="tags">
          <Select.Option value="Online Application & CV">
            Online Application &amp; CV
          </Select.Option>
          <Select.Option value="Phone Interview">Phone Interview</Select.Option>
          <Select.Option value="Online Aptitude Tests">
            Online Aptitude Tests
          </Select.Option>
          <Select.Option value="Assessment Centre">
            Assessment Centre
          </Select.Option>
          <Select.Option value="Partner Interview">
            Partner Interview
          </Select.Option>
        </Select>
      </Form.Item>
    </div>,
  ];
}
