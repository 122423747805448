import Fade from "react-reveal/Fade";
import { Button, Form, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";

export default function Sidebar({ loading, payment }) {
  return (
    <Fade delay={200} duration={600} distance="5px" bottom>
      <div className="card">
        <div className="card-content">
          <h2 className="text-lg mb-5">{payment?.description}</h2>
          <Form.Item name="status" label="Change status">
            <Select>
              <Select.Option value="awaiting_payment">
                Awaiting Payment
              </Select.Option>
              <Select.Option value="cancelled">Cancelled</Select.Option>
              <Select.Option value="paid">Cancelled</Select.Option>
            </Select>
          </Form.Item>
          <Button
            loading={loading}
            type="primary"
            className="mt-5"
            icon={<SaveOutlined />}
            htmlType="submit"
          >
            Save Payment
          </Button>
        </div>
      </div>
    </Fade>
  );
}
