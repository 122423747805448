import { useState } from "react";
import { List, Button, Divider } from "antd";
import * as Icons from "@ant-design/icons";
import classNames from "classnames";
import Hashids from "hashids";
import AnswerModal from "./edit";
import * as R from "ramda";

export default function Answers({ test, question, setQuestion }) {
  const [answer, setAnswer] = useState(null);
  const hashids = new Hashids();

  const onInputChange = ({ key, value = null }, next) => {
    const data = R.assocPath(key, value, answer);
    setAnswer(data, next);
  };

  const onAnswerSave = () => {
    const idx = R.findIndex(R.propEq("id", answer.id), question.answers);
    question.answers[idx] = answer;
    setQuestion(question);
    setAnswer(null);
  };

  const onAnswerDelete = (data) => {
    const answers = R.without([data], question.answers);
    setQuestion({ ...question, answers });
  };

  const onAnswerAdd = () => {
    const id = hashids.encode([
      test.id,
      R.findIndex(R.propEq("id", question.id), test.questions),
      (question?.answers?.length || 0) + 1,
    ]);

    const newAnswer = {
      id,
      content: { type: "text", data: null },
      correct:
        R.either([1, 2, 3], question.type) &&
        R.find(R.propEq("correct", 1), question.answers)
          ? 0
          : null,
    };

    setQuestion({ ...question, answers: [...question.answers, newAnswer] });
    setAnswer(newAnswer);
  };

  const getCorrectAnswer = (item) => {
    let data = "No data";
    switch (question?.type) {
      case 1:
      case 2:
      case 3:
        data =
          item?.correct === 1
            ? "Correct"
            : item?.correct === 0
            ? "Incorrect"
            : "No data";
        return (
          <span
            className={classNames(`block text-xs mt-1 pr-3`, {
              "text-slate-600": item?.correct === null,
              "text-green-600": item?.correct === 1,
              "text-red-500": item?.correct === 0,
            })}
          >
            {data}
          </span>
        );

      case 4:
        data =
          item?.correct === 1
            ? "Worst response"
            : item?.correct === 2
            ? "Best response"
            : "Neither worst nor best response";
        return (
          <span
            className={classNames(`block text-xs mt-1 pr-3`, {
              "text-slate-600": item?.correct !== null,
              "text-red-500": item?.correct === null,
            })}
          >
            {data}
          </span>
        );

      case 5:
        data = `Weight: ${item?.correct || "No data"}`;
        return (
          <span
            className={classNames(`block text-xs mt-1 pr-3`, {
              "text-slate-600": item?.correct !== null,
              "text-red-500": item?.correct === null,
            })}
          >
            {data}
          </span>
        );

      default:
        break;
    }
  };

  return (
    <div>
      <span className="block font-medium text-xs text-slate-600 mb-1">
        Answers
      </span>
      <List
        className="mx-auto"
        pagination={{
          pageSize: 5,
          onShowSizeChange: true,
          size: "small",
        }}
        dataSource={question.answers}
        renderItem={(item, i) => (
          <List.Item>
            <div className="flex w-full justify-between items-center">
              <div className="block">
                <span className="block font-medium text-sm">
                  {item?.content?.data || "No answer content"}
                </span>
                {getCorrectAnswer(item)}
              </div>
              <div className="block" style={{ minWidth: 120 }}>
                <Button
                  onClick={() => setAnswer(item)}
                  type="link"
                  size="small"
                  icon={<Icons.EditOutlined />}
                >
                  Edit
                </Button>
                <Divider type="vertical" />
                <Button
                  onClick={() => onAnswerDelete(item)}
                  size="small"
                  danger
                  shape="link"
                >
                  <Icons.DeleteOutlined />
                </Button>
              </div>
            </div>
          </List.Item>
        )}
      />
      <Button
        disabled={!question.type}
        onClick={onAnswerAdd}
        type="dashed"
        block
        className="my-5"
      >
        Add Answer
      </Button>
      <AnswerModal
        test={test}
        question={question}
        answer={answer}
        onClose={() => setAnswer(null)}
        onInputChange={onInputChange}
        onSave={onAnswerSave}
      />
    </div>
  );
}
