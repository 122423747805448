import { Table, Tag, Button } from "antd";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      scroll={{ x: "max-content" }}
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          align: "left",
          render: (data) => (
            <span className="block text-xs text-slate-500 m-0">{data}</span>
          ),
        },
        {
          title: "Name",
          dataIndex: "display_name",
          key: "display_name",
          align: "left",
          width: 120,
          render: (data, all) => (
            <div>
              <span className="block text-sm font-medium m-0">{data}</span>
              <span className="block text-xs text-slate-500 m-0 mt-1">
                {all.email}
              </span>
            </div>
          ),
        },
        {
          title: "Status",
          dataIndex: "blocked",
          key: "blocked",
          align: "center",
          render: (data) =>
            data ? (
              <Tag className="m-0" color="red">
                Blocked
              </Tag>
            ) : (
              <Tag className="m-0" color="green">
                Active
              </Tag>
            ),
        },
        {
          title: "Role",
          dataIndex: "role",
          key: "role",
          align: "center",
          render: (data) => {
            if (data === "admin")
              return (
                <Tag className="m-0" color="blue">
                  Admin
                </Tag>
              );
            if (data === "member") return <Tag className="m-0">Member</Tag>;
            if (data === "student") return <Tag className="m-0">Student</Tag>;
            if (data === "partner")
              return (
                <Tag color="orange" className="m-0">
                  Partner
                </Tag>
              );
          },
        },
        {
          title: "Last Login",
          dataIndex: "last_login",
          key: "last_login",
          align: "center",
          render: (data) => moment(data).format("HH:mm — DD MMM YYYY"),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Button href={`/users/edit/${all.id}`}>View</Button>
          ),
        },
      ]}
    />
  );
}
