import { Form, Input, Select, Switch } from "antd";
import * as Icons from "@ant-design/icons";

export default function ExtraDetails() {
  return [
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name={["meta", "side_widget"]}
        className="w-full"
        label="Side Widget Script"
        tooltip="Enter side widget script (e.g. tradingview.com/widget)"
      >
        <Input.TextArea
          rows={5}
          placeholder="Optional. Enter widget script (iframe)"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name={["meta", "fullsize_widget"]}
        className="w-full"
        label="Fullsize Widget Script"
        tooltip="Enter side widget script (e.g. tradingview.com/widget)"
      >
        <Input.TextArea
          rows={5}
          placeholder="Optional. Enter widget script (iframe)"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name={["meta", "newsfeed_query"]}
        className="w-full"
        label="Newsfeed Query"
        tooltip="Enter newsfeed search query (e.g. for Volvo you may want to enter The Volvo Group, to filter out irrelevant car news.)"
      >
        <Input placeholder="Enter search query" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name={["meta", "enable_newsfeed"]}
        className="w-full"
        label="Enable Newsfeed"
        tooltip="Newsfeed will load daily news from NewsAPI about the company."
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </div>,
  ];
}
