import { Form, Input } from "antd";
import { useState } from "react";
import moment from "moment";

export default function Details({ user }) {
  const [pass, setPass] = useState(null);

  const togglePassDisplay = () => {
    if (pass) {
      return setPass(null);
    }

    setPass(user?.password_raw);
  };

  return [
    <div className="col-span-4">
      <Form.Item
        name="display_name"
        className="w-full"
        label="Display Name"
        tooltip="Enter display name of the user."
        rules={[
          {
            required: true,
            message: "Please enter the name.",
          },
        ]}
      >
        <Input placeholder="Enter name" />
      </Form.Item>
    </div>,
    <div className="col-span-4">
      <Form.Item
        name="first_name"
        className="w-full"
        label="First Name"
        tooltip="Enter first name of the user."
      >
        <Input placeholder="Enter first name" />
      </Form.Item>
    </div>,
    <div className="col-span-4">
      <Form.Item
        name="last_name"
        className="w-full"
        label="Last Name"
        tooltip="Enter last name of the user."
      >
        <Input placeholder="Enter last name" />
      </Form.Item>
    </div>,
    <div className="col-span-6">
      <Form.Item
        name="email"
        className="w-full"
        label="Email"
        tooltip="Enter user email."
        rules={[
          {
            required: true,
            type: "email",
            message: "Please enter email.",
          },
        ]}
      >
        <Input type="email" placeholder="Enter email" />
      </Form.Item>
    </div>,
    user?.password_raw && (
      <div className="col-span-6">
        <Form.Item name="password" className="w-full" label="Password">
          <span className="text-sm font-semibold text-slate-500">
            {pass || "**********"}{" "}
            <a onClick={togglePassDisplay} className="text-xs font-normal ml-2">
              {pass ? "Hide" : "Show"}
            </a>
          </span>
        </Form.Item>
      </div>
    ),
    <div className="col-span-6">
      <Form.Item name="signup_method" className="w-full" label="Signup Method">
        <span className="text-sm font-semibold">{user.signup_method}</span>
      </Form.Item>
    </div>,
    <div className="col-span-6">
      <Form.Item name="last_login" className="w-full" label="Last Login">
        <span className="text-sm font-semibold">
          {moment(user.last_login).format("HH:mm — DD MMM YYYY")}
        </span>
      </Form.Item>
    </div>,
    <div className="col-span-6">
      <Form.Item
        name="marketing_emails"
        className="w-full"
        label="Marketing Emails"
      >
        <span className="text-sm font-semibold">
          {user.marketing_emails ? "Yes" : "No"}
        </span>
      </Form.Item>
    </div>,
  ];
}
