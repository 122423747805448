import { Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import moment from "moment";

export default function UserDetails({ payment }) {
  return [
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">ID</span>
      <span className="text-sm font-semibold text-black">
        {payment?.user?.id}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Name</span>
      <span className="text-sm font-semibold text-black">
        {payment?.user?.display_name}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Email</span>
      <span className="text-sm font-semibold text-black">
        {payment?.user?.email}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Blocked</span>
      <span className="text-sm font-semibold text-black">
        {payment?.user?.blocked ? "Yes" : "No"}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Last Login</span>
      <span className="text-sm font-semibold text-black">
        {moment(payment?.user?.last_login).format("HH:mm — DD MMM YYYY")}
      </span>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <span className="text-sm text-slate-500 mb-1 block">Lookup</span>
      <span className="text-sm font-semibold text-black">
        <a href={`/users/edit/${payment.user.id}`} target="_blank">
          <Button
            type="link"
            className="m-0 p-0 h-auto"
            icon={<LinkOutlined />}
          >
            View User
          </Button>
        </a>
      </span>
    </div>,
  ];
}
