import { useState } from "react";
import { Button, Divider, List, message, Tag } from "antd";
import Fade from "react-reveal/Fade";
import * as Icons from "@ant-design/icons";
import QuestionModal from "@components/test/questionmodal";
import Hashids from "hashids";
import * as R from "ramda";

export default function Questions({ test, setTest, visible = false }) {
  const { questions } = test;
  const [question, setQuestion] = useState(null);
  const hashids = new Hashids();

  const onQuestionAdd = () => {
    // Generate unique question id
    const id = hashids.encode([test.id, questions.length + 1]);
    const newQuestion = {
      id,
      content: { type: "text", data: null },
      answers: [],
    };
    setTest({
      ...test,
      questions: [...questions, newQuestion],
    });
    setQuestion(newQuestion);
  };

  const onQuestionSave = (data) => {
    if (!data?.answers || data?.answers?.length === 0) {
      return message.error("No answers provided for this question", 2);
    }

    if (R.find(R.propEq("correct", null), data?.answers)) {
      return message.error(
        "All answers should have correct/incorrect label",
        2
      );
    }

    if (!data?.content?.type || !data?.content?.data || !data?.problem) {
      return message.error("Please fill all required fields", 2);
    }

    const idx = R.findIndex(R.propEq("id", data.id), questions);
    questions[idx] = data;

    setTest(
      {
        ...test,
        questions,
      },
      setQuestion(null)
    );
  };

  const onModalClose = () => {
    setQuestion(null);
  };

  const onQuestionDelete = (data) => {
    const q = R.without([data], questions);
    setTest({ ...test, questions: q });
  };

  return (
    <Fade spy={visible} duration={600} distance="5px" bottom>
      <div hidden={!visible} className="card h-full">
        <div className="card-content">
          <span className="block w-full mx-auto text-center text-xl font-bold">
            Add questions.
          </span>

          <List
            className="my-10 mx-auto"
            pagination={{
              pageSize: 10,
              onShowSizeChange: true,
            }}
            dataSource={test.questions}
            renderItem={(item, i) => (
              <List.Item>
                <div className="flex w-full justify-between items-center">
                  <div className="block">
                    <span className="block font-medium text-sm">
                      Question{" "}
                      {R.findIndex(R.propEq("id", item.id), test.questions) + 1}
                    </span>
                    <span className="block text-xs text-slate-600 mt-1">
                      {item?.problem || "No question details"}{" "}
                    </span>
                    <span className="block text-xs text-slate-500 mt-1">
                      {item?.explanation ? (
                        <Tag
                          className="mr-0 text-slate-600"
                          icon={<Icons.CheckCircleFilled />}
                        >
                          Explanations
                        </Tag>
                      ) : (
                        "No Explanations"
                      )}
                      <Divider type="vertical" />
                      {item?.answers?.length || 0} Answers
                    </span>
                  </div>
                  <div className="whitespace-nowrap">
                    <Button
                      className="mr-2"
                      onClick={() => setQuestion(item)}
                      icon={<Icons.EditOutlined />}
                    >
                      Edit
                    </Button>
                    <Button
                      danger
                      type="link"
                      onClick={() => onQuestionDelete(item)}
                    >
                      <Icons.DeleteOutlined />
                    </Button>
                  </div>
                </div>
              </List.Item>
            )}
          />

          <Button onClick={onQuestionAdd} size="large" block>
            Add Question <Icons.PlusCircleOutlined />
          </Button>
        </div>
      </div>
      <QuestionModal
        test={test}
        question={question}
        onClose={onModalClose}
        onSave={onQuestionSave}
      />
    </Fade>
  );
}
