import { Form, Input, Select, InputNumber } from "antd";
import * as Icons from "@ant-design/icons";

export default function Details() {
  return [
    <div className="col-span-12">
      <Form.Item
        name="description"
        className="w-full"
        label="Description"
        tooltip="Enter payment description."
        rules={[
          {
            required: true,
            message: "Please enter payment description.",
          },
        ]}
      >
        <Input placeholder="Enter payment description" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <Form.Item
        name="stripe_payment_id"
        className="w-full"
        label="Stripe Payment ID"
        tooltip="Enter Stripe payment ID."
        rules={[
          {
            required: true,
            message: "Please enter payment id.",
          },
        ]}
      >
        <Input placeholder="Enter Stripe payment id" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <Form.Item
        name="stripe_session_id"
        className="w-full"
        label="Stripe Session ID"
        tooltip="Enter Stripe session ID."
        rules={[
          {
            required: true,
            message: "Please enter session id.",
          },
        ]}
      >
        <Input placeholder="Enter Stripe session id" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <Form.Item
        name="stripe_payment_receipt"
        className="w-full"
        label="Stripe Receipt"
        tooltip="Enter Stripe Receipt URL."
        rules={[
          {
            required: false,
            type: "url",
            message: "Please enter URL.",
          },
        ]}
      >
        <Input
          prefix={<Icons.LinkOutlined />}
          type="url"
          placeholder="Enter Receipt URL"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-2">
      <Form.Item
        name="amount_paid"
        className="w-full"
        label="Amount"
        tooltip="Enter amount to be charged."
        rules={[
          {
            required: true,
            message: "Please enter amount.",
          },
        ]}
      >
        <InputNumber
          className="w-full"
          addonBefore="£"
          controls={false}
          placeholder="Enter Amount in GBP"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-3">
      <Form.Item
        name="card_type"
        className="w-full"
        label="Card Type"
        tooltip="Enter payment card type."
      >
        <Input placeholder="Enter card type" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-3">
      <Form.Item
        name="card_last_digits"
        className="w-full"
        label="Last 4 Digits"
        tooltip="Enter card's last 4 digits."
      >
        <Input minLength={4} maxLength={4} placeholder="Enter 4 digits" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <Form.Item
        name="email"
        className="w-full"
        label="Billing Email"
        tooltip="Enter billing email."
        rules={[
          {
            required: true,
            type: "email",
            message: "Please enter email.",
          },
        ]}
      >
        <Input placeholder="Enter email" />
      </Form.Item>
    </div>,
  ];
}
