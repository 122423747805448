import { Form, Input, Select, DatePicker, message } from "antd";
import * as API from "@api";
import { useEffect, useState } from "react";

export default function Details({ products, order }) {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    (async () => {
      const _res = await API.Companies.getAllPublic();
      if (_res.error || !_res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setCompanies(_res.data.companies);
    })();
  }, []);

  return [
    <div className="col-span-12">
      <Form.Item
        name="description"
        className="w-full"
        label="Description"
        tooltip="Enter order description."
        rules={[
          {
            required: true,
            message: "Please enter order description.",
          },
        ]}
      >
        <Input placeholder="Enter payment description" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-12">
      <Form.Item
        name="products"
        className="w-full"
        label="Products"
        tooltip="Select products associated with this order."
        rules={[
          {
            required: true,
            type: "array",
            message: "Please select products from the list.",
          },
        ]}
      >
        <Select
          placeholder="Select products from the list"
          className="w-full"
          allowClear
          mode="multiple"
          optionFilterProp="id"
          options={products.map((p) => {
            return {
              label: p.name,
              value: p.id,
              hidden: p.linked_products,
            };
          })}
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="expires_at"
        className="w-full"
        label="Expiration Date"
        tooltip="Select expiration date for this order."
        rules={[
          {
            required: true,
            message: "Please select expiration date.",
          },
        ]}
      >
        <DatePicker format="DD MMM YYYY" className="w-full" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      {companies && (
        <Form.Item
          name="company_id"
          className="w-full"
          label="Company"
          tooltip="Select company associated with this order."
          rules={[
            {
              required: false,
              message: "Please select company.",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select company"
            className="w-full"
            allowClear
            options={companies.map((p) => {
              return {
                label: p.name,
                value: p.id,
              };
            })}
          />
        </Form.Item>
      )}
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="created_at"
        className="w-full"
        label="Order created at"
        tooltip="Select order placement date for this order."
        rules={[
          {
            required: true,
            message: "Please select create date.",
          },
        ]}
      >
        <DatePicker disabled format="HH:mm — DD MMM YYYY" className="w-full" />
      </Form.Item>
    </div>,
    <div className="col-span-12 lg:col-span-6">
      <Form.Item
        name="updated_at"
        className="w-full"
        label="Last update"
        rules={[
          {
            required: true,
            message: "Please select date.",
          },
        ]}
      >
        <DatePicker disabled format="HH:mm — DD MMM YYYY" className="w-full" />
      </Form.Item>
    </div>,
  ];
}
