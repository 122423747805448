import { Table, Button, Tag } from "antd";
import * as R from "ramda";
import moment from "moment";

export default function Orders({ user }) {
  return (
    <div className="col-span-12">
      <Table
        dataSource={user.orders}
        scroll={{ x: "max-content" }}
        pagination={{
          current: 1,
          pageSize: 10,
          total: 1,
          position: ["bottomright"],
          showSizeChanger: true,
        }}
        columns={[
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
            align: "left",
            width: 200,
            render: (data, all) => (
              <div>
                <span className="block text-sm font-medium m-0 mb-1">
                  {data}
                </span>
                <span className="block text-xs text-slate-500 mb-2">
                  Order No.: {all.id}
                </span>
                {all.payment ? (
                  <Tag
                    color={
                      all.payment.status === "paid"
                        ? "green"
                        : all.payment.status === "cancelled"
                        ? "red"
                        : "orange"
                    }
                    className="capitalize m-0"
                  >
                    {R.replace("_", " ", all.payment.status)}
                  </Tag>
                ) : (
                  <Tag className="m-0">No Payment</Tag>
                )}
              </div>
            ),
          },
          {
            title: "Expires At",
            dataIndex: "expires_at",
            key: "expires_at",
            align: "center",
            render: (data, all) => {
              if (moment(data).isBefore(moment()))
                return (
                  <Tag className="m-0" color="red">
                    Expired
                  </Tag>
                );
              return (
                <Tag className="m-0">{moment(data).format("DD MMM YYYY")}</Tag>
              );
            },
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            render: (data) => moment(data).format("DD MMM YYYY"),
          },
          {
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, all) => (
              <Button
                type="link"
                className="m-0 p-0 h-auto"
                target="_blank"
                href={`/orders/edit/${all.id}`}
              >
                View
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
}
