import { message, Select, Spin, Form } from "antd";
import * as API from "@api";
import { useState } from "react";
import debounce from "debounce";

export default function UsersFilter(params) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  let fetchUserList = async (value) => {
    setOptions([]);
    setFetching(true);
    const data = await API.Users.getAll({
      limit: 10,
      s: value,
    });

    if (!data || data?.error) {
      return message.error(
        "Unable to perform search, please check your internet connection",
        3
      );
    }

    setOptions(
      data?.data?.rows?.map((user) => ({
        value: user.id,
        label: `${user.display_name} (${user.email})`,
      }))
    );

    setFetching(false);
  };

  fetchUserList = debounce(fetchUserList, 200);

  return (
    <Form.Item label="User" name="user_id">
      <Select
        showSearch
        className="w-full"
        allowClear
        placeholder="Star typing to search"
        filterOption={false}
        onSearch={fetchUserList}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
      />
    </Form.Item>
  );
}
