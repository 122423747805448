import * as Pages from "@pages";

export default function MemberRoutes() {
  return [
    {
      path: "/reviews/new",
      element: <Pages.NewReview />,
    },
    {
      path: "/reviews",
      element: <Pages.Reviews />,
    },
    {
      path: "/products",
      element: <Pages.Products />,
    },
    {
      path: "/users/edit/:id",
      element: <Pages.EditUser />,
    },
    {
      path: "/users",
      element: <Pages.Users />,
    },
    {
      path: "/orders/edit/:id",
      element: <Pages.EditOrder />,
    },
    {
      path: "/orders",
      element: <Pages.Orders />,
    },
    {
      path: "/payments/edit/:id",
      element: <Pages.EditPayment />,
    },
    {
      path: "/payments",
      element: <Pages.Payments />,
    },
    {
      path: "/companies/edit/:id",
      element: <Pages.EditCompany />,
    },
    {
      path: "/companies/new",
      element: <Pages.NewCompany />,
    },
    {
      path: "/companies",
      element: <Pages.Companies />,
    },
    {
      path: "/tests/edit/:id",
      element: <Pages.EditTest />,
    },
    {
      path: "/tests/new",
      element: <Pages.NewTest />,
    },
    {
      path: "/tests",
      element: <Pages.Tests />,
    },
    {
      path: "/",
      element: <Pages.Dashboard />,
    },
  ];
}
