import { useState } from "react";
import { Button, Input, Form, message, Popconfirm } from "antd";
import * as API from "@api";
import * as Icons from "@ant-design/icons";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Password({ user }) {
  const [newPassword, setNewPassword] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onPasswordReset = async () => {
    const token = await executeRecaptcha();
    const _res = await API.Users.resetPassword({ email: user.email, token });

    if (!_res || _res.error) {
      return message.error("Something went wrong. Please try again.", 2);
    }

    return message.success("Password reset email sent", 1, () =>
      document.location.reload()
    );
  };

  const onPasswordChange = async () => {
    if (!newPassword || newPassword.length < 6) {
      return message.error("Password should be at least 6 characters long", 2);
    }

    const _res = await API.Users.update({ id: user.id, password: newPassword });

    if (!_res || _res.error) {
      return message.error("Something went wrong. Please try again.", 2);
    }

    return message.success("Password changed successfully", 1, () =>
      document.location.reload()
    );
  };

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 lg:col-span-1">
          <Form.Item
            label="Password Reset"
            help="New password will be generated and sent to user's email."
          >
            <Popconfirm
              title="Reset user password?"
              onConfirm={onPasswordReset}
              okText="Reset Now"
              cancelText="Cancel"
            >
              <Button type="primary" icon={<Icons.LockOutlined />}>
                Reset Password
              </Button>
            </Popconfirm>
          </Form.Item>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <Form.Item
            label="Password Update"
            help="Type in new password for this user. The change will be applied immediately."
          >
            <div className="flex justify-between items-center">
              <Input
                placeholder="Type new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="rounded-r-none"
              />
              <Button
                onClick={onPasswordChange}
                className="rounded-l-none border-l-0"
                type="primary"
              >
                Update
              </Button>
            </div>
          </Form.Item>
        </div>
      </div>
    </div>
  );
}
